body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.App {
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}
